import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { currency, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { LoadingButton } from '@mui/lab';
import { ShoppingBasket } from '@mui/icons-material';

function PlansPage() {

    const {fetchData, postData} = useApiRequest();

    const [planList, setPlanList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [services, setServices] = useState([]);

    const [planModal, setPlanModal] = useState(false);
    const [planId, setPlanId] = useState(null);
    const [planName, setPlanName] = useState('');
    const [planDetails, setPlanDetails] = useState('');
    const [planType, setPlanType] = useState('kg');
    const [planDiscount, setPlanDiscount] = useState('');
    const [planAmount, setPlanAmount] = useState('');
    const [planPrice, setPlanPrice] = useState('');
    const [planValidity, setPlanValidity] = useState('');
    const [planServices, setPlanServices] = useState([]);
    const [planStatus, setPlanStatus] = useState(true);
    const [partnerShare, setPartnerShare] = useState(0);
    const [agentShare, setAgentShare] = useState(0);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setPlanList([]);
        const resp = await fetchData('plan/list');
        const resp1 = await fetchData('service/list');
        if(resp){
            setPlanList(resp.data);
        }
        if(resp1){
            setServices(resp1.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const addPlan = () =>{
        setPlanId(null);
        setPlanName('');
        setPlanDetails('');
        setPlanType('kg');
        setPlanDiscount('');
        setPlanAmount('');
        setPlanValidity('');
        setPlanPrice('');
        setPlanStatus(true);
        setPlanServices([]);
        setPlanModal(true);
        setPartnerShare(0);
        setAgentShare(0);
    }
    const editPlan = (plan) =>{
        const ids = plan.services.map(service => service.id);
        setPlanId(plan.id);
        setPlanName(plan.name);
        setPlanDetails(plan.details);
        setPlanType(plan.type);
        setPlanDiscount(plan.discount);
        setPlanAmount(plan.amount);
        setPlanPrice(plan.price);
        setPlanValidity(plan.validity);
        setPlanStatus(plan.status);
        setPlanServices(ids);
        setPartnerShare(plan.partnerShare);
        setAgentShare(plan.agentShare);
        setPlanModal(true);
    }

    const handlePlanService = (event) => {
        const {
          target: { value },
        } = event;
        setPlanServices(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const closePlanModal = () =>{
        if(isSubmitting){
            return;
        }
        setPlanModal(false);
    }

    const submitPlan = async() =>{
        let endpoint = "plan/add";
        if(planId){
            endpoint = "plan/edit/"+planId;
        }
        if(planName.trim() === "" || planDetails.trim() === "" || planAmount === "" || planValidity === "" || planPrice === ""){
            return;
        }
        let obj = {
            name: planName,
            details: planDetails,
            amount: planAmount,
            validity: planValidity,
            price: planPrice,
            services: planServices,
            status: planStatus,
            agentShare,
            partnerShare
        }
        if(!planId){
            obj['type'] = planType;
        }
        if(planType === "amt"){
            if(planDiscount === ""){return;}
            obj['discount'] = planDiscount;
        }

        setIsSubmitting(true);
        const resp = await postData(endpoint, obj);
        if(resp){
            setPlanModal(false);
            getOneData();
        }
        setIsSubmitting(false);

    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Plans</Typography>
                <Button variant='contained' onClick={addPlan} >Add Plan</Button>
            </Stack>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4} gap={1}>
                <Box width='10%'>
                    <Typography><b>Name</b></Typography>
                </Box>
                <Box width='10%' paddingX={1}>
                    <Typography><b>Details</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Type</b></Typography>
                </Box>
                <Box width='10%' paddingX={1}>
                    <Typography><b>Discount</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Validity</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Price</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Services</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Partner Share</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Agent Share</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Status</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Edit</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 220px)' overflow='auto' gap={1}>
                {planList.map((plan, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='10%'>
                            <Typography>{plan.name}</Typography>
                        </Box>
                        <Box width='10%' paddingX={1}>
                            <Typography>{plan.details}</Typography>
                        </Box>
                        <Box width='5%'>
                            <Typography>{plan.type.toUpperCase()}</Typography>
                        </Box>
                        <Box width='10%' paddingX={1}>
                            {plan.type === "kg" && <Typography>{plan.amount} KG</Typography>}
                            {plan.type === "amt" && <Typography>{plan.discount}% with {currency} {plan.amount}</Typography>}
                        </Box>
                        <Box width='10%'>
                            <Typography>{plan.validity} Days</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{currency} {plan.price}</Typography>
                        </Box>
                        <Stack width='15%' gap={0.5}>
                            {plan.services.map(srv=>{
                                return <Typography key={srv.id}>{srv.name}</Typography>
                            })}
                            {plan.services.length === 0 && <Typography>ALL</Typography>}
                        </Stack>
                        <Box width='5%'>
                            <Typography>{plan.partnerShare} %</Typography>
                        </Box>
                        <Box width='5%'>
                            <Typography>{plan.agentShare} %</Typography>
                        </Box>
                        <Box width='10%'>
                            {plan.status && <Typography color={'green'}>Active</Typography>}
                            {!plan.status && <Typography color={'red'}>Inactive</Typography>}
                        </Box>
                        <Box width='10%'>
                            <Button variant='contained' color='error' onClick={()=>{editPlan(plan)}} >Edit</Button>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Plans</Typography>
                </Stack>}
                {planList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Plans to show</Typography>
                </Stack>}
            </Stack>

            <Modal open={planModal} onClose={closePlanModal}>
                <Box sx={{position: 'absolute',top: '15%', bottom: '15%', left: 'calc(50% - 250px)', width: 500,bgcolor: 'background.paper', borderRadius: 5, overflowY: 'auto'}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{planId?'Edit':'Add'} Plan</Typography>
                        <TextField label="Plan Name" value={planName} onChange={(e)=>{setPlanName(e.target.value)}} variant="outlined" fullWidth/>
                        <TextField label="Plan Details" value={planDetails} onChange={(e)=>{setPlanDetails(e.target.value)}} variant="outlined" fullWidth multiline rows={3}/>
                        <FormControl fullWidth>
                            <InputLabel>Plan Type</InputLabel>
                            <Select value={planType} onChange={(e)=>{setPlanType(e.target.value)}} variant="outlined" disabled={planId?true:false}>
                                <MenuItem value="kg">KG</MenuItem>
                                <MenuItem value="amt">Amount</MenuItem>
                            </Select>
                        </FormControl>

                        {planType === "amt" && <TextField label="Plan Discount" type='number' value={planDiscount} onChange={(e)=>{setPlanDiscount(e.target.value)}} variant="outlined" fullWidth InputProps={{inputProps: {min: 0, max: 100}, endAdornment: <InputAdornment position='end'>%</InputAdornment>}}/>}

                        <TextField label="Plan Value" type='number' value={planAmount} onChange={(e)=>{setPlanAmount(e.target.value)}} variant="outlined" fullWidth InputProps={{inputProps: {min: 0}, endAdornment: <InputAdornment position='end'>{planType==="kg"?'KG':''}</InputAdornment>, startAdornment: <InputAdornment position='start'>{planType==="amt"?currency:''}</InputAdornment>}}/>

                        <TextField label="Plan Validity" type='number' value={planValidity} onChange={(e)=>{setPlanValidity(e.target.value)}} variant="outlined" fullWidth InputProps={{inputProps: {min: 1}, endAdornment: <InputAdornment position='end'>Days</InputAdornment>}}/>

                        <TextField label="Plan Price" type='number' value={planPrice} onChange={(e)=>{setPlanPrice(e.target.value)}} variant="outlined" fullWidth InputProps={{inputProps: {min: 0}, startAdornment: <InputAdornment position='start'>{currency}</InputAdornment>}}/>

                        <FormControl fullWidth>
                            <InputLabel>Services</InputLabel>
                            <Select multiple value={planServices} onChange={handlePlanService} input={<OutlinedInput label="Chip" />} >
                                {services.map(srv=>{
                                    return <MenuItem key={srv.id} value={srv.id}>{srv.name}</MenuItem>
                                })}
                            </Select>
                            <Typography sx={{fontSize: 12}}><i>Don't select anything to apply for all services</i></Typography>
                        </FormControl>

                        <TextField label="Partner Share" type='number' value={partnerShare} onChange={(e)=>{setPartnerShare(e.target.value)}} variant="outlined" fullWidth InputProps={{inputProps: {min: 0, max: 100}, endAdornment: <InputAdornment position='end'>%</InputAdornment>}}/>

                        <TextField label="Agent Share" type='number' value={agentShare} onChange={(e)=>{setAgentShare(e.target.value)}} variant="outlined" fullWidth InputProps={{inputProps: {min: 0, max: 100}, endAdornment: <InputAdornment position='end'>%</InputAdornment>}}/>

                        <FormControl fullWidth>
                            <InputLabel>Plan Status</InputLabel>
                            <Select value={planStatus} onChange={(e)=>{setPlanStatus(e.target.value)}} variant="outlined">
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <LoadingButton fullWidth onClick={submitPlan} variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<ShoppingBasket/>}><span>{planId?'Update':'Add'} Plan</span></LoadingButton>
                    </Stack>
                </Box>
           </Modal>
        </Stack>
    )
}

export default PlansPage