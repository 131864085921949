import { Stack, Typography } from '@mui/material'
import React from 'react'

function EmptyDashboard() {
    return (
        <Stack p={4} alignItems={'center'}>
            <Typography variant='h5'>Welcome</Typography>
        </Stack>
    )
}

export default EmptyDashboard