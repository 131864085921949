import React, { useCallback, useEffect, useState } from 'react'
import { useCommonUI } from '../context/UI';
import { useApiRequest } from '../store/Common';
import { Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import { DeleteForever, Settings } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

function SettingsPage() {

    const {fetchData, postData} = useApiRequest();
    const {openSnackbar} = useCommonUI();
    const [configs, setConfigs] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [bannerImages, setBannerImages] = useState([]);

    const getData = useCallback(async() =>{
        setIsLoading(true);
        const resp = await fetchData('app/settings');
        if(resp){
            const configData = resp.data.reduce((acc, item) => {
                const { name, id, ...rest } = item;
                acc[name] = rest;
                return acc;
            }, {});
            setConfigs(configData);
            setBannerImages(configData.bannerImages.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getData();
    },[getData]);

    const handleConfig = (e) =>{
        const { name, value, dataset } = e.target;
        if(dataset.ctype === "data"){
            setConfigs({ 
                ...configs, 
                [name]: { 
                    ...configs[name], 
                    data: value 
                } 
            });
        }else if(dataset.ctype === "num"){
            setConfigs({ 
                ...configs, 
                [name]: { 
                    ...configs[name], 
                    num: value 
                } 
            });
        }else if(dataset.ctype === "commission"){
            let comiConfig = configs.commission;
            if(name === "pcn"){
                comiConfig['partnerShare'] = value;
            }else if(name === "acn"){
                comiConfig['agentShare'] = value;
            }else if(name === "pcwn"){
                comiConfig['partnerWalletShare'] = value;
            }else if(name === "acwn"){
                comiConfig['agentWalletShare'] = value;
            }
            setConfigs({ ...configs, commission: comiConfig });
        }
    }

    const handleAddImage = () => {
        const newId = bannerImages.length > 0 ? bannerImages[bannerImages.length - 1].id + 1 : 1;
        setBannerImages([...bannerImages, { id: newId, link: "" }]);
    };
    const handleUpdateImage = (index, value) => {
        const updatedImages = [...bannerImages];
        updatedImages[index].link = value;
        setBannerImages(updatedImages);
    };
    const handleDeleteImage = (index) => {
        const updatedImages = bannerImages.filter((_, i) => i !== index);
        updatedImages.forEach((img, i) => (img.id = i + 1)); // Reassign IDs sequentially
        setBannerImages(updatedImages);
    };

    const updateSettings = async() =>{
        if(configs.invpre.data === "" || configs.invoicepre.data === "" || isNaN(configs.invnum.num) || isNaN(configs.invoicenum.num) || isNaN(configs.commission.partnerShare) || isNaN(configs.commission.agentShare) || isNaN(configs.commission.partnerWalletShare) || isNaN(configs.commission.agentWalletShare) || configs.invnum.num === "" || configs.invoicenum.num === "" || configs.commission.partnerShare === "" || configs.commission.agentShare === "" || configs.commission.partnerWalletShare === "" || configs.commission.agentWalletShare === ""){
            openSnackbar("Some Fields Empty", "error");
            return;
        }
        setIsSubmitting(true);
        const obj = {
            invpre: configs.invpre.data,
            invnum: parseInt(configs.invnum.num),
            invoicepre: configs.invoicepre.data,
            invoicenum: parseInt(configs.invoicenum.num),
            bannerImages: bannerImages,
            commission: {
                partnerShare: parseFloat(configs.commission.partnerShare),
                agentShare: parseFloat(configs.commission.agentShare),
                partnerWalletShare: parseFloat(configs.commission.partnerWalletShare),
                agentWalletShare: parseFloat(configs.commission.agentWalletShare)
            }
        }
        const resp = await postData("app/settings", obj);
        if(resp){
            openSnackbar("Settings Updated", "success");
        }
        setIsSubmitting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} gap={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Settings</Typography>
                </Stack>}

                {configs && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Stack justifyContent={'space-between'}>
                            <Typography variant='h5'>Settings</Typography>
                        </Stack>
                    </Stack>

                    <Stack p={2} gap={2}>
                        <Stack flexDirection={'row'} gap={2}>
                            <TextField name="invpre" inputProps={{"data-ctype": "data"}} label="Order No Prefix" value={configs.invpre.data} onChange={handleConfig} variant="outlined" fullWidth required/>

                            <TextField type='number' inputProps={{"data-ctype": "num"}} name="invnum" label="Order Number" value={configs.invnum.num} onChange={handleConfig} variant="outlined" fullWidth required/>
                        </Stack>

                        <Stack flexDirection={'row'} gap={2}>
                            <TextField name="invoicepre" inputProps={{"data-ctype": "data"}} label="Invoice Prefix" value={configs.invoicepre.data} onChange={handleConfig} variant="outlined" fullWidth required/>

                            <TextField type='number' inputProps={{"data-ctype": "num"}} name="invoicenum" label="Invoice Number" value={configs.invoicenum.num} onChange={handleConfig} variant="outlined" fullWidth required/>
                        </Stack>

                        <Stack flexDirection={'row'} gap={2}>
                            <TextField type='number' name="pcn" label="Partner Commission Normal" value={configs.commission.partnerShare} onChange={handleConfig} variant="outlined" fullWidth required InputProps={{inputProps: {min: 0, max: 100, "data-ctype": "commission"}, endAdornment: <InputAdornment position='end'>%</InputAdornment>}}/>

                            <TextField type='number' name="acn" label="Agent Commission Normal" value={configs.commission.agentShare} onChange={handleConfig} variant="outlined" fullWidth required InputProps={{inputProps: {min: 0, max: 100, "data-ctype": "commission"}, endAdornment: <InputAdornment position='end'>%</InputAdornment>}}/>
                        </Stack>

                        <Stack flexDirection={'row'} gap={2}>
                            <TextField type='number' name="pcwn" label="Partner Commission Wallet" value={configs.commission.partnerWalletShare} onChange={handleConfig} variant="outlined" fullWidth required InputProps={{inputProps: {min: 0, max: 100, "data-ctype": "commission"}, endAdornment: <InputAdornment position='end'>%</InputAdornment>}}/>

                            <TextField type='number' name="acwn" label="Agent Commission Wallet" value={configs.commission.agentWalletShare} onChange={handleConfig} variant="outlined" fullWidth required InputProps={{inputProps: {min: 0, max: 100, "data-ctype": "commission"}, endAdornment: <InputAdornment position='end'>%</InputAdornment>}}/>
                        </Stack>

                        <Stack mt={2} gap={2}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography>App Banner Images</Typography>
                                <Button size='small' variant='contained' onClick={handleAddImage}>Add Image Link</Button>
                            </Stack>
                            <Stack gap={2}>
                                {bannerImages.map((img, index)=>{
                                    return <Stack key={img.id} flexDirection={'row'} gap={2}>
                                            <TextField label={`Images ${index + 1}`} value={img.link} variant="outlined" fullWidth required onChange={(e) => handleUpdateImage(index, e.target.value)}/>
                                            <IconButton color='error' onClick={() => handleDeleteImage(index)}>
                                                <DeleteForever />
                                            </IconButton>
                                        </Stack>
                                })}
                            </Stack>
                        </Stack>
  
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Settings/>} onClick={updateSettings}><span>Update Settings</span></LoadingButton>

                    </Stack>
                </Stack>}
            </Stack>
        </Stack>
    )
}

export default SettingsPage