import { Close, SearchTwoTone } from '@mui/icons-material'
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Typography, Button } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { DateRangePicker } from 'rsuite'
import { currency, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { BarChart } from '@mui/x-charts';
import moment from 'moment';

function Dashboard() {

    const {fetchData, postData} = useApiRequest();
    const {combine, allowedMaxDays, afterToday} = DateRangePicker;
    const [isLoading, setIsLoading] = useState(false);
    const [isGraphLoading, setIsGraphLoading] = useState(false);
    const [isCGraphLoading, setIsCGraphLoading] = useState(false);
    const [orderStat, setOrderStat] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const screenWidth = window.innerWidth - 50;

    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState('all');

    const [graphDate, setGraphDate] = useState([new Date(new Date().setDate(new Date().getDate() - 30)), new Date()]);
    const [graphData, setGraphData] = useState(null);
    const [graphMonthlyData, setGraphMonthlyData] = useState(null);

    const [dateRange1, setDateRange1] = useState(null);
    const [dateRange2, setDateRange2] = useState(null);
    const [cGraphData, setCGraphData] = useState(null);

    const getOrderData = useCallback(async(body) =>{
        setIsLoading(true);
        setOrderStat(null);
        const resp = await postData('stat/order', body);
        if(resp){
            setOrderStat(resp.data);
        }
        setIsLoading(false);
    },[postData])

    useEffect(()=>{
        const getStore = async() =>{
            const resp1 = await fetchData('store/listcenter');
            if(resp1){
                setStores(resp1.data);
            }
        }
        getStore();
    },[fetchData]);

    useEffect(()=>{
        getOrderData({store: 'all', start: 0, end: 0});
    },[getOrderData]);

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setSelectedStores('all');
        setSelectedDate(null);
        getOrderData({store: 'all', start: 0, end: 0});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].setHours(0, 0, 0, 0)
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            store: selectedStores,
            start,
            end
        }
        getOrderData(body);
    }

    const loadDailyGraph = useCallback(async(graphDate) =>{
        if(!graphDate){
            return;
        }
        let start = 0;
        let end = 0;
        if(graphDate && graphDate.length === 2){
            start = graphDate[0].setHours(0, 0, 0, 0)
            end = graphDate[1].setHours(23, 59, 59, 999)
        }else{
            return;
        }
        setIsGraphLoading(true);
        setGraphData(null);
        const resp = await postData('stat/graph', {start, end});
        if(resp){
            setGraphData(resp.data);
        }
        setIsGraphLoading(false);
    }, [postData]);

    const loadDailyGraphMonthly = useCallback(async() =>{
        const resp = await postData('stat/monthly-graph');
        if(resp){
            setGraphMonthlyData(resp.data);
        }
    }, [postData]);
    useEffect(()=>{
        loadDailyGraphMonthly();
    },[loadDailyGraphMonthly])

    useEffect(()=>{
        loadDailyGraph(graphDate);
    },[graphDate, loadDailyGraph]);

    const loadCompareGraphs = async() =>{
        if(!dateRange1 || !dateRange2){
            return;
        }
        let start = 0;
        let end = 0;
        if(dateRange1 && dateRange1.length === 2){
            start = dateRange1[0].setHours(0, 0, 0, 0)
            end = dateRange1[1].setHours(23, 59, 59, 999)
        }else{
            return;
        }
        let start2 = 0;
        let end2 = 0;
        if(dateRange2 && dateRange2.length === 2){
            start2 = dateRange2[0].setHours(0, 0, 0, 0)
            end2 = dateRange2[1].setHours(23, 59, 59, 999)
        }else{
            return;
        }
        const range1Interval = (dateRange1[1] - dateRange1[0]) / (1000 * 60 * 60 * 24);
        const range2Interval = (dateRange2[1] - dateRange2[0]) / (1000 * 60 * 60 * 24);
        if (range1Interval !== range2Interval) {
            alert('The intervals of the two date ranges must be equal.');
            return;
        }
        setIsCGraphLoading(true);
        setCGraphData(null);
        const resp = await postData('stat/comparegraph', {start, end, start2, end2});
        if(resp){
            setCGraphData(resp.data);
        }
        setIsCGraphLoading(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <Box width={450}>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                    </Box>
                    <FormControl fullWidth>
                        <InputLabel>Store</InputLabel>
                        <Select value={selectedStores} onChange={(e)=>{setSelectedStores(e.target.value)}} >
                            <MenuItem value={'all'}>{'All Stores'}</MenuItem>
                            {stores.map(store=>{
                                return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                <ClipLoader size={15} color='#000' />
                <Typography variant='body'>Loading Data</Typography>
            </Stack>}
            {orderStat && <Grid container>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Sales</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {parseFloat(orderStat.totalSales + orderStat.subPurchase).toFixed(2)}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Payment Received</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {parseFloat(orderStat.totalPayment+ (orderStat.subPurchase-orderStat.unpaidSubs)).toFixed(2)}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Payment Received</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalPayment}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Value</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalValue}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Sales</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalSales}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Subscription Order Sales</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalSubscriptonOrder}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Paid</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalPaid}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Dues</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalUnpaid}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Orders</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalOrders}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Processing Orders</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalPendingOrders}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Completed Orders</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalCompletedOrders}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Average Order Value</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {parseFloat(orderStat.totalValue/orderStat.totalOrders).toFixed(2)}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Cash at Store</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.cashStore}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Cash at Rider</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.cashAtRiderTotal}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Subscriptions</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalSubsCount}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Active Subscriptions</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.activeSubsCount} </Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Subscripton Purchases</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalSubPurchases}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Subscripton Purchase Value</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.subPurchase}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Unpaid Subscriptons Value</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.unpaidSubs}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Expense Value</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {parseFloat(orderStat.totalExpense).toFixed(2)}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Expenses</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.expenseCount}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total New Customers</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.customers}</Typography>
                    </Stack>
                </Grid>
            </Grid>}

            <Stack mt={4} p={2} gap={3}>
                <Typography variant='h5'>Compare Graphs</Typography>
                <Stack flexDirection={'row'} gap={2} p={2} sx={{background: '#fff', borderRadius: 4, boxShadow: '5px 3px 5px #00000005'}}>
                    <Box width={300}>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range 1" shouldDisableDate={combine(allowedMaxDays(60), afterToday())} value={dateRange1} onChange={setDateRange1} />
                    </Box>
                    <Box width={300}>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range 2" shouldDisableDate={allowedMaxDays(60)} value={dateRange2} onChange={setDateRange2} />
                    </Box>
                    <Button variant="contained" onClick={loadCompareGraphs}>Load Graphs</Button>
                </Stack>

                {isCGraphLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Graph Data</Typography>
                </Stack>}
                {cGraphData && <Box>
                    <BarChart
                        xAxis={[
                            {
                                scaleType: 'band',
                                data: cGraphData.order.map((item, index) => `Day ${index + 1}`),
                            },
                        ]}
                        series={[
                            {
                                data: cGraphData.order.map((item, index) => item.totalSales),
                                color: "#007FFF",
                                valueFormatter: (value, context) => cGraphData.order[context.dataIndex]?moment(cGraphData.order[context.dataIndex].date).format("Do MMM YYYY")+` - ₹${value}`:''
                            },
                            {
                                data: cGraphData.order2.map((item, index) => item.totalSales),
                                valueFormatter: (value, context) => cGraphData.order2[context.dataIndex]?moment(cGraphData.order2[context.dataIndex].date).format("Do MMM YYYY")+` - ₹${value}`:'',
                                color: "#FF5733",
                            },
                        ]}
                        width={screenWidth}
                        height={800}/>
                    <Typography textAlign={'center'} variant='h6'>Orders Sales</Typography>
                </Box>}


                {cGraphData && <Box>
                    <BarChart
                        xAxis={[
                            {
                                scaleType: 'band',
                                data: cGraphData.order.map((item, index) => `Day ${index + 1}`),
                            },
                        ]}
                        series={[
                            {
                                data: cGraphData.order.map((item, index) => item.totalOrders),
                                color: "#007FFF",
                                valueFormatter: (value, context) => cGraphData.order[context.dataIndex]?moment(cGraphData.order[context.dataIndex].date).format("Do MMM YYYY")+` - ${value}`:''
                            },
                            {
                                data: cGraphData.order2.map((item, index) => item.totalOrders),
                                valueFormatter: (value, context) => cGraphData.order2[context.dataIndex]?moment(cGraphData.order2[context.dataIndex].date).format("Do MMM YYYY")+` - ${value}`:'',
                                color: "#FF5733",
                            },
                        ]}
                        width={screenWidth}
                        height={800}/>
                    <Typography textAlign={'center'} variant='h6'>Total Orders Count</Typography>
                </Box>}

                {cGraphData && <Box>
                    <BarChart
                        xAxis={[
                            {
                                scaleType: 'band',
                                data: cGraphData.order.map((item, index) => `Day ${index + 1}`),
                            },
                        ]}
                        series={[
                            {
                                data: cGraphData.subscription.map((item, index) => item.totalSales),
                                color: "#007FFF",
                                valueFormatter: (value, context) => cGraphData.subscription[context.dataIndex]?moment(cGraphData.subscription[context.dataIndex].date).format("Do MMM YYYY")+` - ₹${value}`:''
                            },
                            {
                                data: cGraphData.subscription2.map((item, index) => item.totalSales),
                                valueFormatter: (value, context) => cGraphData.subscription2[context.dataIndex]?moment(cGraphData.subscription2[context.dataIndex].date).format("Do MMM YYYY")+` - ₹${value}`:'',
                                color: "#FF5733",
                            },
                        ]}
                        width={screenWidth}
                        height={800}/>
                    <Typography textAlign={'center'} variant='h6'>Subscription Sales</Typography>
                </Box>}

                {cGraphData && <Box>
                    <BarChart
                        xAxis={[
                            {
                                scaleType: 'band',
                                data: cGraphData.order.map((item, index) => `Day ${index + 1}`),
                            },
                        ]}
                        series={[
                            {
                                data: cGraphData.subscription.map((item, index) => item.totalOrders),
                                color: "#007FFF",
                                valueFormatter: (value, context) => cGraphData.subscription[context.dataIndex]?moment(cGraphData.subscription[context.dataIndex].date).format("Do MMM YYYY")+` - ${value}`:''
                            },
                            {
                                data: cGraphData.subscription2.map((item, index) => item.totalOrders),
                                valueFormatter: (value, context) => cGraphData.subscription2[context.dataIndex]?moment(cGraphData.subscription2[context.dataIndex].date).format("Do MMM YYYY")+` - ${value}`:'',
                                color: "#FF5733",
                            },
                        ]}
                        width={screenWidth}
                        height={800}/>
                    <Typography textAlign={'center'} variant='h6'>Subscription Purchased</Typography>
                </Box>}

                {cGraphData && <Box>
                    <BarChart
                        xAxis={[
                            {
                                scaleType: 'band',
                                data: cGraphData.order.map((item, index) => `Day ${index + 1}`),
                            },
                        ]}
                        series={[
                            {
                                data: cGraphData.customer.map((item, index) => item.totalCustomers),
                                color: "#007FFF",
                                valueFormatter: (value, context) => cGraphData.customer[context.dataIndex]?moment(cGraphData.customer[context.dataIndex].date).format("Do MMM YYYY")+` - ${value}`:''
                            },
                            {
                                data: cGraphData.customer2.map((item, index) => item.totalCustomers),
                                valueFormatter: (value, context) => cGraphData.customer2[context.dataIndex]?moment(cGraphData.customer2[context.dataIndex].date).format("Do MMM YYYY")+` - ${value}`:'',
                                color: "#FF5733",
                            },
                        ]}
                        width={screenWidth}
                        height={800}/>
                    <Typography textAlign={'center'} variant='h6'>New Customers Joined</Typography>
                </Box>}

            </Stack>

            <Stack mt={4} p={2} gap={3}>
                <Box width={450}>
                    <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={combine(allowedMaxDays(60), afterToday())} value={graphDate} onChange={setGraphDate} />
                </Box>
                {isGraphLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Graph Data</Typography>
                </Stack>}
                {graphData && <Box>
                    <BarChart
                        dataset={graphData.order}
                        xAxis={[
                            {
                                scaleType: 'band',
                                categoryGapRatio: 0.3,
                                barGapRatio: 0.1,
                                data: graphData.order.map((item) => moment(item.date).format("Do MMM")),
                                tickPlacement: 'middle',
                            },
                        ]}
                        series={[
                            { dataKey: 'totalSales', label: 'Daily Order Sales (₹)', color: '#d9166f' }
                        ]}
                        width={screenWidth}
                        height={800}
                        />
                    <Typography textAlign={'center'} variant='h6'>Daily Orders Sales</Typography>
                </Box>}
                {graphData && <Box>
                    <BarChart
                        dataset={graphData.order}
                        xAxis={[
                            {
                                scaleType: 'band',
                                categoryGapRatio: 0.3,
                                barGapRatio: 0.1,
                                data: graphData.order.map((item) => moment(item.date).format("Do MMM")),
                                tickPlacement: 'middle',
                            },
                        ]}
                        series={[
                            { dataKey: 'totalOrders', label: 'Daily Orders', color: '#d9166f' },
                        ]}
                        width={screenWidth}
                        height={800}
                        />
                    <Typography textAlign={'center'} variant='h6'>Daily no. of Orders</Typography>
                </Box>}

                {graphMonthlyData && <Box>
                    <BarChart
                        dataset={graphMonthlyData.order}
                        xAxis={[
                            {
                                scaleType: 'band',
                                categoryGapRatio: 0.3,
                                barGapRatio: 0.1,
                                dataKey: 'month',
                                tickPlacement: 'middle',
                            },
                        ]}
                        series={[
                            { dataKey: 'totalSales', label: 'Monthly Order Sales (₹)', color: '#d9166f' }
                        ]}
                        width={screenWidth}
                        height={800}
                        />
                    <Typography textAlign={'center'} variant='h6'>Monthly Orders Sales</Typography>
                </Box>}
                {graphMonthlyData && <Box>
                    <BarChart
                        dataset={graphMonthlyData.order}
                        xAxis={[
                            {
                                scaleType: 'band',
                                categoryGapRatio: 0.3,
                                barGapRatio: 0.1,
                                dataKey: 'month',
                                tickPlacement: 'middle',
                            },
                        ]}
                        series={[
                            { dataKey: 'totalOrders', label: 'Monthly Total Order Count', color: '#d9166f' },
                        ]}
                        width={screenWidth}
                        height={800}
                        />
                    <Typography textAlign={'center'} variant='h6'>Monthly Total Order Count</Typography>
                </Box>}

                {graphData && <Box>
                    <BarChart
                        dataset={graphData.subscription}
                        xAxis={[
                            {
                                scaleType: 'band',
                                categoryGapRatio: 0.3,
                                barGapRatio: 0.1,
                                data: graphData.order.map((item) => moment(item.date).format("Do MMM")),
                                tickPlacement: 'middle',
                            },
                        ]}
                        series={[
                            { dataKey: 'totalSales', label: 'Daily Subscription Sales (₹)', color: '#d9166f' },
                        ]}
                        width={screenWidth}
                        height={800}
                        />
                    <Typography textAlign={'center'} variant='h6'>Daily Subscriptions Sales</Typography>
                </Box>}
                {graphData && <Box>
                    <BarChart
                        dataset={graphData.subscription}
                        xAxis={[
                            {
                                scaleType: 'band',
                                categoryGapRatio: 0.3,
                                barGapRatio: 0.1,
                                data: graphData.order.map((item) => moment(item.date).format("Do MMM")),
                                tickPlacement: 'middle',
                            },
                        ]}
                        series={[
                            { dataKey: 'totalOrders', label: 'Daily Subscriptions Purchased Count', color: '#d9166f' },
                        ]}
                        width={screenWidth}
                        height={800}
                        />
                    <Typography textAlign={'center'} variant='h6'>Daily Subscriptions Purchased Count</Typography>
                </Box>}

                {graphMonthlyData && <Box>
                    <BarChart
                        dataset={graphMonthlyData.subscription}
                        xAxis={[
                            {
                                scaleType: 'band',
                                categoryGapRatio: 0.3,
                                barGapRatio: 0.1,
                                dataKey: 'month',
                                tickPlacement: 'middle',
                            },
                        ]}
                        series={[
                            { dataKey: 'totalSales', label: 'Monthly Subscription Sales (₹)', color: '#d9166f' },
                        ]}
                        width={screenWidth}
                        height={800}
                        />
                    <Typography textAlign={'center'} variant='h6'>Monthly Subscriptions Sales</Typography>
                </Box>}
                {graphMonthlyData && <Box>
                    <BarChart
                        dataset={graphMonthlyData.subscription}
                        xAxis={[
                            {
                                scaleType: 'band',
                                categoryGapRatio: 0.3,
                                barGapRatio: 0.1,
                                dataKey: 'month',
                                tickPlacement: 'middle',
                            },
                        ]}
                        series={[
                            { dataKey: 'totalOrders', label: 'Monthly Subscriptions Count', color: '#d9166f' },
                        ]}
                        width={screenWidth}
                        height={800}
                        />
                    <Typography textAlign={'center'} variant='h6'>Monthly Subscriptions Count</Typography>
                </Box>}

                {graphData && <Box>
                    <BarChart
                        dataset={graphData.customer}
                        xAxis={[
                            {
                                scaleType: 'band',
                                categoryGapRatio: 0.3,
                                barGapRatio: 0.1,
                                data: graphData.order.map((item) => moment(item.date).format("Do MMM")),
                                tickPlacement: 'middle',
                            },
                        ]}
                        series={[
                            { dataKey: 'totalCustomers', label: 'Total Customers', color: '#d9166f' },
                        ]}
                        width={screenWidth}
                        height={800}
                        />
                    <Typography textAlign={'center'} variant='h6'>Daily New Customers Joined</Typography>
                </Box>}

                {graphMonthlyData && <Box>
                    <BarChart
                        dataset={graphMonthlyData.customerJoin}
                        xAxis={[
                            {
                                scaleType: 'band',
                                categoryGapRatio: 0.3,
                                barGapRatio: 0.1,
                                dataKey: "month",
                                tickPlacement: 'middle',
                            },
                        ]}
                        series={[
                            { dataKey: 'totalCustomers', label: 'Monthly Customers Joined', color: '#d9166f' },
                        ]}
                        width={screenWidth}
                        height={800}
                        />
                    <Typography textAlign={'center'} variant='h6'>Monthly New Customers Joined</Typography>
                </Box>}
            </Stack>
            
        </Stack>
    )
}

export default Dashboard