import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Stack, Tab, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { currency, storeApiUrl, useApiRequest } from '../store/Common'
import { AddHome, Edit, ExpandMore, Handyman, Note, NotificationsActive, Person, Subscriptions } from '@mui/icons-material'
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { useCommonUI } from '../context/UI';
import { toast } from 'react-toastify';

function CustomerDetails() {

    const { customerId } = useParams();
    const {fetchData, postData} = useApiRequest();
    const {openSnackbar, openDialog} = useCommonUI();
    const [customerData, setCustomerData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [editAddress, setEditAddress] = useState({ name: '', phone: '', address: '', city: '', state: '', pincode: '', location: {latitude: '', longitude: ''} });
    const [addAddress, setAddAddress] = useState({ name: '', phone: '', address: '', city: '', state: '', pincode: '' });
    const [unpaidSubs, setUnpaidSubs] = useState(0);

    const [isSubmittingAddress, setIsSubmittingAddress] = useState(false);
    const [editAddressModal, setEditAddressModal] = useState(false);
    const [addAddressModal, setAddAddressModal] = useState(false);
    const [states, setStates] = useState([]);

    const [tabValue, setTabValue] = useState('1');
    const [orderPage, setOrderPage] = useState(null);
    const [orders, setOrders] = useState([]);
    const [isLoadingOrder, setIsLoadingOrder] = useState(false);
    const [totalOrder, setTotalOrder] = useState(0);

    const [subsPage, setSubsPage] = useState(null);
    const [subsList, setSubsList] = useState([]);
    const [isLoadingSubs, setIsLoadingSubs] = useState(false);
    const [totalSubs, setTotalSubs] = useState(0);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoadingPlans, setIsLoadingPlans] = useState(false);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('none');
    const [subPayMode, setSubPayMode] = useState('Cash');
    const [payId, setPayId] = useState('');
    const [isBuyingSub, setIsBuyingSub] = useState(false);
    const [planModal, setPlanModal] = useState(false);

    const [subsNote, setSubsNote] = useState('');
    const [subsNoteId, setSubsNoteId] = useState('');
    const [noteModal, setNoteModal] = useState(false);
    const [isUpdatingNote, setIsUpdatingNote] = useState(false);

    const [updateName, setUpdateName] = useState('');
    const [updatePhone, setUpdatePhone] = useState('');
    const [updateGst, setUpdateGst] = useState('');
    const [updateModal, setUpdateModal] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const [notiModal, setNotiModal] = useState(false);
    const [notiHeader, setNotiHeader] = useState('');
    const [notiBody, setNotiBody] = useState('');
    const [notiImage, setNotiImage] = useState('');
    const [notiCategory, setNotiCategory] = useState('e3c3632e-a9dd-4799-9bcf-69d5fe4ef075');
    const [isSubmittingNotification, setIsSubmittingNotification] = useState(false);

    useEffect(()=>{
        const getStates = async() =>{
            const resp = await fetchData('app/states');
            if(resp){
                setStates(resp.data);
            }
        }
        getStates();
    },[fetchData]);

    const loadOrders = useCallback(async(page)=>{
        setIsLoadingOrder(true);
        const resp = await fetchData('customer/orders/'+customerId+'/'+page);
        if(resp){
            if(page === 1){
                setOrders(resp.data.docs);
            }else{
                setOrders((prevArray) => [...prevArray, ...resp.data.docs]);
            }
            setOrderPage(resp.data.nextPage);
            setTotalOrder(resp.data.totalDocs);
        }
        setIsLoadingOrder(false);
    },[fetchData, customerId]);

    const loadSubs = useCallback(async(page)=>{
        setIsLoadingSubs(true);
        const resp = await fetchData('customer/subscriptions/'+customerId+'/'+page);
        if(resp){
            if(page === 1){
                setSubsList(resp.data.docs);
            }else{
                setSubsList((prevArray) => [...prevArray, ...resp.data.docs]);
            }
            setTotalSubs(resp.data.totalDocs);
            setSubsPage(resp.data.nextPage);
        }
        setIsLoadingSubs(false);
    },[fetchData, customerId]);

    const getData = useCallback(async(cid)=>{
        setIsLoading(true);
        const resp = await fetchData('customer/details/'+cid);
        if(resp){
            setCustomerData(resp.data);
            loadOrders(1);
            loadSubs(1);
            setUnpaidSubs(resp.unpaidSubs);
        }   
        setIsLoading(false);
    },[fetchData, loadOrders, loadSubs]);

    useEffect(()=>{
        getData(customerId);
    },[customerId, getData]);

    const openEditAddress = (item) => {
        setEditAddress({...item, state: item.state.id});
        setEditAddressModal(true);
    }
    const handleEditAddress = (e) => {
        const { name, value } = e.target;
        setEditAddress({ ...editAddress, [name]: value });
    };
    const closeEditModal = () =>{
        if(isSubmittingAddress){
            return;
        }
        setEditAddressModal(false);
    }
    const submitEditAddress = async(e) => {
        e.preventDefault();
        setIsSubmittingAddress(true);
        const resp = await postData('customer/address/edit',{...editAddress, userid: customerId});
        if(resp){
            setEditAddressModal(false);
            getData(customerId);
        }
        setIsSubmittingAddress(false);
    }

    const openNewAddressModal = () =>{
        setAddAddress({...addAddress, name: customerData.name, phone: customerData.phone});
        setAddAddressModal(true);
    }
    const handleNewAddress = (e) => {
        const { name, value } = e.target;
        setAddAddress({ ...addAddress, [name]: value });
    };
    const closeAddModal = () =>{
        if(isSubmittingAddress){
            return;
        }
        setAddAddressModal(false);
    }
    const submitNewAddress = async(e) => {
        e.preventDefault();
        setIsSubmittingAddress(true);
        const resp = await postData('customer/address',{...addAddress, userid: customerId});
        if(resp){
            setAddAddressModal(false);
            setAddAddress({ name: '', phone: '', address: '', city: '', state: '', pincode: '' });
            getData(customerId);
        }
        setIsSubmittingAddress(false);
    }

    const deleteSub = (item, pur) =>{
        if(isDeleting){
            return;
        }
        openDialog("Delete Subscription for "+item.plan.name+"?","Date: "+moment(pur.created).format('Do MMM YYYY h:mm a')+"<br>Amount: "+currency+" "+pur.price, confirmDelete, {subId: item.id, purId: pur.id});
    }

    const confirmDelete = async(obj) =>{
        const tid = toast.loading('Deleting Subscription',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await postData('customer/subscription/delete', obj);
        if(resp){
            toast.update(tid, { render: "Subscription Deleted", type: "success", isLoading: false, autoClose: 2000 });
            loadSubs(1);
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }
    
    const addWalletBalance = async() =>{
        const number = prompt("Enter the amount to add to the wallet:");
        if (number && !isNaN(number)) {
            const amount = parseFloat(number);
            const resp = await postData('customer/wallet/'+customerId, {amount});
            const tid = toast.loading('Adding Wallet Balance',{position:'bottom-right'});
            if(resp){
                toast.update(tid, { render: "Wallet Balance Added", type: "success", isLoading: false, autoClose: 2000 });
                getData(customerId);
            }else{
                toast.dismiss();
            }
        } else {
            openSnackbar("Please enter a valid number", "error");
        }
    }

    const openPlanModal = async() =>{
        setSelectedPlan('none');
        setSubPayMode('Cash');
        setPayId('');
        setPlans([]);
        setPlanModal(true);
        setIsLoadingPlans(true);
        const resp = await fetchData('app/plans');
        if(resp){
            setPlans(resp.data);
        }
        setIsLoadingPlans(false);
    }
    const closePlanModal = () =>{
        if(isBuyingSub){
            return;
        }
        setPlanModal(false)
    }
    const buySubscription = async() =>{
        if(selectedPlan === "none"){
            return;
        }
        setIsBuyingSub(true);
        const resp = await postData('customer/subscription', {userid: customerId, plan: selectedPlan.id, mode: subPayMode, payId: payId});
        if(resp){
            setPlanModal(false);
            openSnackbar('Subscription Purchased', 'success');
            loadSubs(1);
        }
        setIsBuyingSub(false);
    }

    const openNoteModal = (sub) =>{
        setSubsNote(sub.note);
        setSubsNoteId(sub.id)
        setNoteModal(true);
    }
    const closeNoteModal = () =>{
        if(isUpdatingNote){
            return;
        }
        setNoteModal(false);
    }
    const updateNote = async() =>{
        setIsUpdatingNote(true);
        const resp = await postData('subscription/note',{id: subsNoteId, note: subsNote});
        if(resp){
            setNoteModal(false);
            openSnackbar('Note Updated', 'success');
            loadSubs(1);
        }
        setIsUpdatingNote(false);
    }

    const openUpdateModal = () =>{
        setUpdateName(customerData.name);
        setUpdatePhone(customerData.phone);
        setUpdateGst(customerData.gst?customerData.gst:"");
        setUpdateModal(true);
    }
    const closeUpdateModal = () =>{
        if(isUpdating){
            return;
        }
        setUpdateModal(false);
    }
    const updateCustomer = async() =>{
        if(updateName === ""){
            return;
        }
        const regex = /^\d{10}$/;
        if(!regex.test(updatePhone)){
            openSnackbar('Phone Number Incorrect', 'error');
            return;
        }
        setIsUpdating(true);
        const resp = await postData('customer/update/'+customerId, {name: updateName, phone: updatePhone, gst: updateGst});
        if(resp){
            openSnackbar('Customer Updated', "success");
            getData(customerId);
            setUpdateModal(false);
        }
        setIsUpdating(false);
    }

    const openNotification = () =>{
        setNotiHeader('');
        setNotiBody('');
        setNotiImage('');
        setNotiModal(true);
    }
    const closeNotiModal = () =>{
        if(isSubmittingNotification){
            return;
        }
        setNotiModal(false);
    }
    const sendNoti = async() =>{
        if(notiHeader === "" && notiBody === "" && notiImage === ""){
            openSnackbar("Nothing Entered", "error");
            return;
        }
        setIsSubmittingNotification(true);
        const resp = await postData('customer/notification/'+customerId, {header: notiHeader, body: notiBody, image: notiImage, category: notiCategory});
        if(resp){
            openSnackbar('Notification sent', "success");
            setNotiModal(false);
        }
        setIsSubmittingNotification(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}} mb={2}>
                <ClipLoader size={15} color='#000' />
                <Typography variant='body'>Loading Customer Details</Typography>
            </Stack>}

            {customerData && unpaidSubs > 0 && <Box mb={2}><Alert variant="filled" severity="error">
                Customer has {unpaidSubs} UNPAID Subscriptions.
            </Alert></Box>}

            {customerData && <Stack flexDirection='row' gap={3}>
                <Stack sx={{background: '#fff', padding: 2, borderRadius: 3, boxShadow: '5px 3px 5px #00000005', flex: 1}}>
                    <Typography variant='h6'><b>Customer Name: </b>{customerData.name}</Typography>
                    <Typography variant='h6'><b>Customer Phone: </b>{customerData.phone}</Typography>
                    {customerData.gst && <Typography variant='h6'><b>GSTIN: </b>{customerData.gst}</Typography>}
                </Stack>
                <Stack sx={{background: '#fff', padding: 2, borderRadius: 3, boxShadow: '5px 3px 5px #00000005', flex: 1}}>
                    <Typography variant='h6'><b>Wallet Balance: </b>{currency} {customerData.wallet}</Typography>
                    <Typography variant='h6'><b>Refer Code: </b>{customerData.referCode}</Typography>
                    {customerData.partner && <Typography variant='h6'><b>Society/ Agent: </b>{customerData.partner.name}</Typography>}
                </Stack>
                <Stack sx={{background: '#fff', padding: 2, borderRadius: 3, boxShadow: '5px 3px 5px #00000005', flex: 1}}>
                    <Typography variant='h6'><b>Joined: </b>{moment.utc(customerData.createdAt).local().format('Do MMMM YYYY h:mm a')}</Typography>
                    <Typography variant='h6'><b>App User:</b> {customerData.app?"Yes":"No"}</Typography>
                    {customerData.partner && customerData.partner.manager && <Typography variant='h6'><b>Partner: </b>{customerData.partner.manager.name}</Typography>}
                </Stack>
            </Stack>}
            {customerData && <Stack px={2} flexDirection='row' justifyContent='space-between' mt={3}>
                <Typography variant='h6'><b>Addresses</b></Typography>
                <Stack style={{flexDirection: 'row', gap: 10}}>
                    {customerData.app && <Button variant='contained' onClick={openNotification}>Send Push Notification</Button>}
                    <Button variant='contained' onClick={openPlanModal} color='info'>Add Subscription</Button>
                    <Button variant='contained' onClick={openNewAddressModal} color='warning'>Add Address</Button>
                    <Button variant='contained' onClick={addWalletBalance}>Add Wallet Balance</Button>
                    <Button variant='contained' onClick={openUpdateModal} color='error'>Edit</Button>
                </Stack>
            </Stack>}
            {customerData && <Grid container>
                {customerData.addresses.map((address)=>{
                    return <Grid item xs={3} key={address._id}>
                        <Stack m={2} p={2} flexDirection={'row'} gap={1} sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', justifyContent: 'space-between'}}>
                            <Stack>
                                <Typography variant='body'>{address.name}</Typography>
                                <Typography variant='body'>{address.phone}</Typography>
                                {address.address.split('\n').map((line, index)=>{
                                    return <Typography key={`${address._id}_${index}`} variant='body'>{line}</Typography>
                                })}
                                <Typography variant='body'>{address.city}, {address.state.name} - {address.pincode}</Typography>
                            </Stack>
                            <Stack>
                                <IconButton color='primary' onClick={()=>{openEditAddress(address)}}>
                                    <Edit/>
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Grid>
                })}
            </Grid>}

            <Box sx={{width: '100%'}}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e,nv)=>{setTabValue(nv)}} centered>
                            <Tab label={`Orders (${totalOrder})`} value="1" sx={{flex: 1}} />
                            <Tab label={`Subscriptions (${totalSubs})`} value="2" sx={{flex: 1}} />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                            <Box width='15%'>
                                <Typography><b>Created</b></Typography>
                            </Box>
                            <Box width='10%'>
                                <Typography><b>Order Id</b></Typography>
                            </Box>
                            <Box width='15%'>
                                <Typography><b>Pickup Store</b></Typography>
                            </Box>
                            <Box width='15%'>
                                <Typography><b>Delivery Store</b></Typography>
                            </Box>
                            <Box width='10%'>
                                <Typography><b>Items</b></Typography>
                            </Box>
                            <Box width='10%'>
                                <Typography><b>Status</b></Typography>
                            </Box>
                            <Box width='10%'>
                                <Typography><b>Total</b></Typography>
                            </Box>
                            <Box width='10%'>
                                <Typography><b>Payment</b></Typography>
                            </Box>
                            <Box width='5%'>
                                <Typography><b>Details</b></Typography>
                            </Box>
                        </Stack>
                        <Stack mt={1} p={2} pt={0} gap={1}>
                            {orders.map((order, index)=>{
                                let payColor = order.payStatus?'green':"error";
                                return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                                    <Box width='15%'>
                                        <Typography>{moment(order.created).format('Do MMM yyyy hh:mm a')}</Typography>
                                    </Box>
                                    <Box width='10%'>
                                        <Typography>{order.orderId}</Typography>
                                    </Box>
                                    <Box width='15%'>
                                        {order.pickupStore && <Typography>{order.pickupStore.name}</Typography>}
                                        {!order.pickupStore && order.store && <Typography>{order.store.name}</Typography>}
                                    </Box>
                                    <Box width='15%'>
                                        {order.deliveryStore && <Typography>{order.deliveryStore.name}</Typography>}
                                    </Box>
                                    <Box width='10%'>
                                        <Typography>{order.totalItems}</Typography>
                                    </Box>
                                    <Box width='10%'>
                                        <Typography>{order.status}</Typography>
                                    </Box>
                                    <Box width='10%'>
                                        <Typography>{order.total}</Typography>
                                    </Box>
                                    <Box width='10%'>
                                        <Typography color={payColor}>{order.payType}</Typography>
                                    </Box>
                                    <Box width='5%'>
                                        <Link to={`/orders/${order.id}`}>
                                            <Button variant='contained' size='small'>Details</Button>
                                        </Link>
                                    </Box>
                                </Stack>
                            })}
                            {isLoadingOrder && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                                <ClipLoader size={15} color='#000' />
                                <Typography variant='body'>Loading Orders</Typography>
                            </Stack>}
                            {orders.length === 0 && !isLoadingOrder && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant='body'>No Orders to show</Typography>
                            </Stack>}
                        </Stack>
                        {!isLoadingOrder && orderPage && <Stack alignItems={'center'}>
                            <Box>
                                <Button variant='contained' onClick={()=>{loadOrders(orderPage)}}>Load More Orders</Button>
                            </Box>
                        </Stack>}
                    </TabPanel>
                    <TabPanel value="2">
                    <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                            <Box width='20%'>
                                <Typography><b>Created</b></Typography>
                            </Box>
                            <Box width='25%'>
                                <Typography><b>Plan Name</b></Typography>
                            </Box>
                            <Box width='10%'>
                                <Typography><b>Remaining</b></Typography>
                            </Box>
                            <Box width='10%'>
                                <Typography><b>Purchased</b></Typography>
                            </Box>
                            <Box width='23%'>
                                <Typography><b>Expiry</b></Typography>
                            </Box>
                            <Box width='7%'>
                                <Typography><b>Note</b></Typography>
                            </Box>
                        </Stack>
                        <Stack mt={1} p={2} pt={0} gap={1}>
                            {subsList.map((subs, index)=>{
                                let remaining = `${currency} ${subs.remaining}`;
                                if(subs.plan.type === "kg"){
                                    remaining = `${subs.remaining} Kg`;
                                }
                                let rColor = '';
                                if(subs.remaining === 0){
                                    rColor = 'error';
                                }
                                let eColor = '';
                                if(subs.expiry < Date.now()){
                                    eColor = 'error';
                                }
                                const hasUnpaid = subs.purchases.some(purchase => purchase.payStatus === 'U' && !purchase.cancelled);
                                return <Stack key={index} width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                                    <Stack flexDirection='row' p={1.2} alignItems='center' width='100%' >
                                        <Box width='20%'>
                                            <Typography>{moment(subs.created).format('Do MMMM YYYY h:mm a')}</Typography>
                                        </Box>
                                        <Stack width='25%' flexDirection={'row'} gap={1}>
                                            <Typography>{subs.plan.name}</Typography>
                                            {hasUnpaid && <Typography fontWeight={'bold'} color='red'>UNPAID</Typography>}
                                        </Stack>
                                        <Box width='10%'>
                                            <Typography color={rColor}>{remaining}</Typography>
                                        </Box>
                                        <Box width='10%'>
                                            <Typography>{subs.purchases.length}</Typography>
                                        </Box>
                                        <Box width='23%'>
                                            <Typography color={eColor}>{moment(subs.expiry).format('Do MMMM YYYY h:mm a')}</Typography>
                                        </Box>
                                        <Box width='7%'>
                                            <Button variant='contained' color='warning' onClick={()=>{openNoteModal(subs)}}>Note</Button>
                                        </Box>
                                    </Stack>
                                    {subs.note && subs.note !== "" && <Box px={2} pb={1}>
                                        <Typography><b>NOTE</b></Typography>
                                        {subs.note.split('\n').map((line, index)=>{
                                            return <Typography key={index}>{line}</Typography>
                                        })}  
                                    </Box>}
                                    <Box>
                                        <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                <Typography fontWeight={'bold'}>Purchases</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Stack flexDirection='row' alignItems='center' width='100%' mb={0.5} >
                                                    <Box width='15%'><Typography><b>Date</b></Typography></Box>
                                                    <Box width='15%'><Typography><b>Payment Type</b></Typography></Box>
                                                    <Box width='10%'><Typography><b>Value</b></Typography></Box>
                                                    <Box width='15%'><Typography><b>Price</b></Typography></Box>
                                                    <Box width='15%'><Typography><b>Transaction ID</b></Typography></Box>
                                                    <Box width='10%'><Typography><b>Payment</b></Typography></Box>
                                                    <Box width='10%'><Typography><b>Invoice</b></Typography></Box>
                                                    <Box width='10%'><Typography><b>Delete</b></Typography></Box>
                                                </Stack>
                                                {subs.purchases.map((pur)=>{
                                                    let payStatus = 'Unpaid';
                                                    let tColor = 'error';
                                                    if(pur.payStatus === "S"){
                                                        payStatus = pur.payBy;
                                                        tColor = 'green'
                                                    }else if(pur.payStatus === "P"){
                                                        payStatus = "Processing";
                                                        tColor = ''
                                                    }
                                                    let value = `${currency} ${pur.amount}`;
                                                    if(subs.plan.type === "kg"){
                                                        value = `${pur.amount} Kg`;
                                                    }
                                                    return <Stack key={pur.id} width={'100%'} flexDirection={'row'} marginBottom={1}>
                                                        <Box width={'15%'}><Typography>{moment(pur.created).format('Do MMMM YYYY h:mm a')}</Typography></Box>
                                                        <Box width={'15%'}><Typography color={tColor}>{payStatus}</Typography></Box>
                                                        <Box width='10%'><Typography>{value}</Typography></Box>
                                                        <Stack width='15%' flexDirection={'row'} gap={1}>
                                                            <Typography>{currency} {pur.price}</Typography>
                                                            <Typography sx={{fontWeight: 'bold', color: 'red'}}>{pur.cancelled?"(CANCELLED)":""}</Typography>
                                                        </Stack>
                                                        <Box width='15%'><Typography>{pur.payId}</Typography></Box>
                                                        <Box width='10%'>
                                                            {pur.payStatus !== "U" && pur.payDate && <Typography>{moment(pur.payDate).format('Do MMMM YYYY h:mm a')}</Typography>}
                                                        </Box>
                                                        <Box width='10%'>
                                                            <Link to={`${storeApiUrl}subscription/invoice/${pur.id}`} target='_blank'><Button variant='contained' size='small'>Invoice</Button></Link>
                                                        </Box>
                                                        <Box width='10%'>
                                                            {!pur.cancelled && <Button variant='contained' color='error' onClick={()=>{deleteSub(subs, pur)}} size='small'>Delete</Button>}
                                                        </Box>
                                                    </Stack>
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Stack>
                            })}
                            {isLoadingSubs && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                                <ClipLoader size={15} color='#000' />
                                <Typography variant='body'>Loading Subscriptions</Typography>
                            </Stack>}
                            {subsList.length === 0 && !isLoadingSubs && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant='body'>No Subscriptions to show</Typography>
                            </Stack>}
                        </Stack>
                        {!isLoadingSubs && subsPage && <Stack alignItems={'center'}>
                            <Box>
                                <Button variant='contained' onClick={()=>{loadSubs(subsPage)}}>Load More Subscriptions</Button>
                            </Box>
                        </Stack>}
                    </TabPanel>
                </TabContext>
            </Box>

            <Modal open={addAddressModal} onClose={closeAddModal}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitNewAddress}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add New Address</Typography>
                        <TextField label="Name" name='name' value={addAddress.name} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={addAddress.phone} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <TextField label="Address" name='address' value={addAddress.address} onChange={handleNewAddress} variant="outlined" fullWidth multiline rows={4} required/>
                        <TextField label="City" name='city' value={addAddress.city} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <FormControl fullWidth required>
                            <InputLabel>State</InputLabel>
                            <Select name="state" value={addAddress.state} onChange={handleNewAddress} variant="outlined">
                                {states.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField label="Pincode" name='pincode' type='number' value={addAddress.pincode} onChange={handleNewAddress} variant="outlined" fullWidth required/>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingAddress}  loadingPosition='start' startIcon={<AddHome/>}><span>Add New Address</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={editAddressModal} onClose={closeEditModal}>
                <Box component="form" sx={{position: 'absolute',top: '10%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitEditAddress}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Edit Address</Typography>
                        <TextField label="Name" name='name' value={editAddress.name} onChange={handleEditAddress} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={editAddress.phone} onChange={handleEditAddress} variant="outlined" fullWidth required/>
                        <TextField label="Address" name='address' value={editAddress.address} onChange={handleEditAddress} variant="outlined" fullWidth multiline rows={4} required/>
                        <TextField label="City" name='city' value={editAddress.city} onChange={handleEditAddress} variant="outlined" fullWidth required/>
                        <FormControl fullWidth required>
                            <InputLabel>State</InputLabel>
                            <Select name="state" value={editAddress.state} onChange={handleEditAddress} variant="outlined">
                                {states.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField label="Pincode" name='pincode' type='number' value={editAddress.pincode} onChange={handleEditAddress} variant="outlined" fullWidth required/>
                        <TextField label="Latitude" type='number' value={editAddress.location.latitude} variant="outlined" fullWidth disabled/>
                        <TextField label="Longitude" type='number' value={editAddress.location.longitude} variant="outlined" fullWidth disabled/>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingAddress}  loadingPosition='start' startIcon={<Handyman/>}><span>Update Address</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={planModal} onClose={closePlanModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Buy Subscription</Typography>
                        <FormControl fullWidth>
                            <InputLabel>Select Plan</InputLabel>
                            <Select value={selectedPlan} onChange={(e)=>{setSelectedPlan(e.target.value)}} variant="outlined" disabled={isLoadingPlans}>
                                <MenuItem value='none'>None</MenuItem>
                                {plans.map((plan) => {
                                    return <MenuItem key={plan.id} value={plan}>{plan.name}</MenuItem>
                                })}
                            </Select>
                            {isLoadingPlans && <Stack direction='row' gap={1} sx={{alignItems: 'center'}}>
                                <ClipLoader size={15} color='#000' />
                                <Typography variant='body'>Loading Plans</Typography>
                            </Stack>}
                        </FormControl>
                        {selectedPlan !=="none" && <Stack>
                            <Typography><b>{selectedPlan.name}</b></Typography>
                            <Typography>{selectedPlan.details}</Typography>
                            <Typography>Validity: {selectedPlan.validity} Days</Typography>
                            <Typography>Price: {currency}{selectedPlan.price}</Typography>
                        </Stack>}
                        {selectedPlan !=="none" && <FormControl fullWidth>
                            <InputLabel>Select Payment Mode</InputLabel>
                            <Select value={subPayMode} onChange={(e)=>{setSubPayMode(e.target.value)}} variant="outlined">
                                <MenuItem value={'Cash'}>Cash</MenuItem>
                                <MenuItem value={'Card'}>Card</MenuItem>
                                <MenuItem value={'UPI'}>UPI</MenuItem>
                            </Select>
                        </FormControl>}
                        {subPayMode !== "Cash" && <TextField label="Payment ID" variant='outlined' fullWidth value={payId} onChange={(e)=>{setPayId(e.target.value)}} />}
                        {selectedPlan !=="none" && <LoadingButton fullWidth variant="contained" color="primary" loading={isBuyingSub} loadingPosition='start' startIcon={<Subscriptions/>} onClick={buySubscription}><span>Buy Subscription</span></LoadingButton>}
                    </Stack>
                </Box>
            </Modal>

            <Modal open={noteModal} onClose={closeNoteModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Edit Note</Typography>
                        <TextField label="Note" sx={{marginTop: 2}} value={subsNote} onChange={(e)=>{setSubsNote(e.target.value)}} variant="outlined" fullWidth multiline rows={3}/>
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isUpdatingNote} loadingPosition='start' startIcon={<Note/>} onClick={updateNote}><span>Update Note</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={updateModal} onClose={closeUpdateModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Edit Customer</Typography>

                        <TextField label="Name" name='name' value={updateName} onChange={(e)=>{setUpdateName(e.target.value)}} variant="outlined" fullWidth required/>
                        <TextField label="Phone" type='number' value={updatePhone} onChange={(e)=>{setUpdatePhone(e.target.value)}} variant="outlined" fullWidth required/>

                        <TextField label="GST" value={updateGst} onChange={(e)=>{setUpdateGst(e.target.value)}} variant="outlined" fullWidth />
                        
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isUpdating} loadingPosition='start' startIcon={<Person/>} onClick={updateCustomer}><span>Update Customer</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={notiModal} onClose={closeNotiModal}>
                <Box sx={{position: 'absolute',top: '10%', bottom: '10%', left: '10%', right: '10%', bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                        <Stack flex={1} p={2} height={'100%'}>
                            <Typography variant='h4'>Push Notification</Typography>
                            <Typography variant='h6'>1 customer selected</Typography>
                            <Stack gap={2} mt={3} flex={1}>
                                <TextField label="Notification Header" value={notiHeader} onChange={(e)=>{setNotiHeader(e.target.value)}} variant="outlined" fullWidth/>

                                <TextField label="Notification Body" value={notiBody} onChange={(e)=>{setNotiBody(e.target.value)}} variant="outlined" fullWidth multiline rows={4} required/>

                                <TextField label="Notification Image Link" type='url' value={notiImage} onChange={(e)=>{setNotiImage(e.target.value)}} variant="outlined" fullWidth/>

                                <FormControl fullWidth>
                                    <InputLabel>Notification Channel</InputLabel>
                                    <Select label="Notification Channel" value={notiCategory} onChange={(e)=>{setNotiCategory(e.target.value)}} >
                                        <MenuItem value='e3c3632e-a9dd-4799-9bcf-69d5fe4ef075'>Promotion</MenuItem>
                                        <MenuItem value='87bf5eb1-559a-4ab7-904b-4d9f0822da25'>Order Updates</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Box mt={2}>
                                <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmittingNotification}  loadingPosition='start' startIcon={<NotificationsActive/>} onClick={sendNoti}><span>Send Notifications</span></LoadingButton>
                            </Box>
                        </Stack>
                        <Stack flex={1} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                            <Stack className='notiPhone' height={'calc(100% - 100px)'} sx={{marginTop: '10%', marginBottom: '10%', width: '450px'}}>
                                <Box sx={{margin: 'auto', width: '404px', borderRadius: '28px', background: '#fff'}} p={2}>
                                    <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                                        <img src='/assets/onesignal.png' width='20px' alt='' />
                                        <Typography variant='body2'>Dryfi Laundry</Typography>
                                    </Stack>
                                    <Typography my={0.5}><b>{notiHeader}</b></Typography>
                                    <Stack my={0.5}>
                                        {(() => {
                                            const lines = notiBody.split('\n');
                                            return lines.map((line, index) => {
                                                if (index < 3) {
                                                    return <Typography key={index} variant='body'>{line}</Typography>
                                                }
                                                if (index === 3) {
                                                    return <Typography key={index} variant='body'>{line}{lines.length > 4? " ...":"" }</Typography>
                                                }
                                                return null;
                                            });
                                        })()}
                                    </Stack>
                                    {notiImage !== "" && <img src={notiImage} width='100%' alt='' />}
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default CustomerDetails